<template>
  <div class="experiences" data-view>
    <hero-with-image
      title='go <span class="np-underline">eco–friendly</span> <span class="whitespace-nowrap">for your wedding</span> <span class="whitespace-nowrap">at <span class="whitespace-nowrap">‘neera retreat’.</span></span>'
      description="our eco-wedding packages focus on eco-friendly, recyclable, or reusable items and give you an opportunity to opt-in helping the environment, lowering carbon emissions & food waste by using seasonal and local products, at the same time having a beautiful wedding of your dreams. "
      :imageUrl="require('@/assets/experiences/wedding/main.png')"
      class="mb-18 lg:mb-20 lg:mt-8"
    />
    <photo-horizon data-aos="fade-up" class="my-18 lg:my-20" :photos="images" />
    <neera-grid class="my-18 lg:my-20">
      <neera-grid-row data-aos="fade-up">
        <div>
          <img
            class="img-para w-full rounded-tr-neera lg:rounded-tr-np-lg"
            src="@/assets/experiences/wedding/03_decorations.png"
          />
        </div>
        <div class="text-left lg:pl-8 py-2 space-y-4 max-w-screen-lg/2">
          <p class="saoltext text-np-green font-light text-np-2xl">
            eco–friendly decorations
          </p>
          <ul
            class="list-none list-inside text-np-green font-light content-para"
          >
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>decorative details from nature</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>natural materials</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>reusable materials</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>upcycled wedding decorations</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>farm-to-table live flowers and plants</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>low energy LED fairy lights</p>
              </div>
            </li>
          </ul>
        </div>
      </neera-grid-row>
      <neera-grid-row data-aos="fade-up">
        <div>
          <img
            class="img-para w-full"
            src="@/assets/experiences/wedding/04_dining.png"
          />
        </div>
        <div
          id="ripple-cafe"
          class="text-left lg:pl-8 py-2 space-y-4 max-w-screen-lg/2"
        >
          <p class="saoltext text-np-green font-light text-np-2xl">dining</p>
          <ul
            class="list-none list-inside text-np-green font-light content-para"
          >
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>seasonal and local ingredients</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>farm-to-table</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p>colorful fruits and vegetables as edible decorations</p>
              </div>
            </li>
          </ul>
        </div>
      </neera-grid-row>
    </neera-grid>
    <quote-break
      data-aos="fade-up"
      class="my-18 lg:my-20"
      title="eco–wedding"
      description="
    <p>
craft up the wedding of your dreams in either our outdoor <span class='saoltext whitespace-nowrap'>‘neera green space'</span> with natural lighting and an amazing river view of the <span class='whitespace-nowrap'>Tha Chin River</span> or have a private ceremony indoors with our unique & eco-decorations.
 </p><p>
celebrate a day to remember with your family and friends while witnessing your first dance under the fairy lights and end the night in our romantic panoramic suite (Lake).
    </p>
    "
    />
    <neera-container data-aos="fade-up" class="my-18 lg:my-20">
      <div class="flex flex-col">
        <div class="flex flex-col gap-y-4">
          <div class="order-1">
            <img
              class="flex lg:hidden w-screen overflow-x-scroll"
              src="@/assets/experiences/spaces/capacity.svg"
            />
            <img
              class="hidden lg:flex w-full"
              src="@/assets/experiences/spaces/capacity.svg"
            />
          </div>
          <div class="order-2 space-y-2">
            <p
              class="text-center lg:text-right font-light text-np-green text-np-14"
            >
              download PDF for more detail and floor plan
            </p>
            <div class="grid grod-cols-1 lg:grid-cols-2 gap-x-10 lg:gap-x-16">
              <div
                class="flex justify-center lg:justify-end lg:col-start-2 col-span-1 gap-x-8"
              >
                <neera-solid-button
                  class="w-1/2"
                  text="download pdf"
                  :linkTo="false"
                  staticFileSlug="neeraretreathotel-eco-wedding-floor-plan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </neera-container>

    <other-experiences
      data-aos="fade-up"
      exclude-path="/experiences/eco-wedding"
    />
  </div>
</template>

<script>
import HeroWithImage from "../../components/HeroWithImage.vue";
import OtherExperiences from "../../components/OtherExperiences.vue";
import PhotoHorizon from "../../components/PhotoHorizon.vue";
import QuoteBreak from "../../components/QuoteBreak.vue";
import NeeraContainer from "../../components/layout/NeeraContainer.vue";
import NeeraGrid from "../../components/layout/NeeraGrid.vue";
import NeeraGridRow from "../../components/layout/NeeraGridRow.vue";
import NeeraSolidButton from "../../components/NeeraSolidButton.vue";
import AOS from "aos";

export default {
  components: {
    OtherExperiences,
    PhotoHorizon,
    QuoteBreak,
    HeroWithImage,
    NeeraContainer,
    NeeraGrid,
    NeeraGridRow,
    NeeraSolidButton,
  },
  name: "EcoWedding",
  created() {
    const title = "go eco–friendly for your wedding at ‘neera retreat’.";
    const description =
      "our eco-wedding packages focus on eco-friendly, recyclable, or reusable items and give you an opportunity to opt-in helping the environment, lowering carbon emissions & food waste by using seasonal and local products, at the same time having a beautiful wedding of your dreams.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  data() {
    return {
      images: [
        {
          image_url: require("@/assets/experiences/wedding/ecowedding_02-photolayout-01.png"),
        },
        {
          image_url: require("@/assets/experiences/wedding/ecowedding_02-photolayout-02.png"),
        },
        {
          image_url: require("@/assets/experiences/wedding/ecowedding_02-photolayout-03.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.title-italic {
  font-family: "Saol Italic" !important;
  font-style: italic;
  font-weight: 300;
}

.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #905235;
}

button {
  font-family: "Saol Text" !important;
  color: white;
  background: #994e2e;
  border-radius: 10px;
}

.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}
</style>
