<template>
    <div class="grid grid-cols-1 justify-between lg:grid-cols-3 gap-4 lg:gap-8">
        <div
            class="w-full items-center justify-center"
            v-for="(item, index) in photos"
            :key="index">
            <img class="w-full max-h-128" :src="item.image_url" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'PhotoHorizon',
    props: {
        photos: {
            type: Array
        }
	},
}
</script>
